const date = new Date();
const year = date.getFullYear();

const APPCONFIG = {

  host: process.env.REACT_APP_BACKEND,
  redirectOnErrorUrl: process.env.REACT_APP_BACKEND,
  appFrontendHostname: process.env.REACT_APP_FRONTEND_HOSTNAME,
  appBackendHostname: process.env.REACT_APP_BACKEND,
  axiosTimeout: process.env.REACT_APP_AXIOS_TIMEOUT,

  //host: 'https://timeabsencemanagementbackend.azurewebsites.net/',
  //redirectOnErrorUrl: 'https://timeabsencemanagementbackend.azurewebsites.net/',

  logoutUrl: process.env.REACT_APP_BACKEND + 'logout',
  helpUrl: 'https://apps.ce.collabserv.com/wikis/home?lang=en-us#!/wiki/W24d3718ee9ad_46cf_a22d_05bae698e27b/page/Time%20%26%20Absence%20Management',


  permissionDeniedUrl: '#/user/notallowed',
  /* used on fake auth*/
  permissionDeniedPathName: '/user/notallowed',


  brand: 'BESI MaterialAdjustmentTools',
  brandHeaderName: 'Material Adjustment Tools',
  user: 'Lisa',
  year,
  AutoCloseMobileNav: true,          // true, false. Automatically close sidenav on route change (Mobile only)
  showCustomizer: false,              // Boolean: true, false. Customizer will be opened (visible) first time app was loaded if set to true
  color: {
    primary: '#00BCD4',
    success: '#8BC34A',
    info: '#66BB6A',
    infoAlt: '#7E57C2',
    warning: '#FFCA28',
    danger: '#F44336',
    text: '#3D4051',
    gray: '#EDF0F1'
  },
  settings: {
    layoutBoxed: false,               // true, false
    navCollapsed: false,              // true, false
    navBehind: true,                 // true, false
    fixedHeader: true,                // true, false
    sidebarWidth: 'middle',           // small, middle, large
    colorOption: '14',                // String: 11,12,13,14,15,16; 21,22,23,24,25,26; 31,32,33,34,35,36
    theme: 'light',                   // light, gray, dark
  }
};

export default APPCONFIG;

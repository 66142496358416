import React from 'react';
import ReactDOM from 'react-dom';
import {setConfig } from 'react-hot-loader';
import configureStore, { history } from './store/configureStore';
import Root from './components/Root';
import axios from 'axios';
import * as serviceWorker from './serviceWorker';
import { getAuthToken, setAuthToken } from './services/auth';
import Cookies from 'universal-cookie';

const store = configureStore();
const query = new URLSearchParams(window.location.search);
const token = query.get('token')
let showContent = true;

console.log("hello world");

if (token) {
  console.log("token found");
  setAuthToken(token);
  const cookies = new Cookies();

  if (cookies.get('mySavedUrl')) {
    showContent = false;
    console.log("saved url found");
    window.location.href = cookies.get('mySavedUrl');
  }
  else {
    console.log("no saved url found");
  }
}
else {
  console.log("no token found");
}

if (showContent) {

  axios.defaults.headers.common['Authorization'] = 'Bearer ' + getAuthToken();

  setConfig({
    showReactDomPatchNotification: false
  })


  ReactDOM.render(<Root store={store} history={history} />, document.getElementById('root'));


  if (module.hot) {
    module.hot.accept('./components/Root', () => {
      const NewRoot = require('./components/Root').default;
      ReactDOM.render(<NewRoot store={store} history={history} />, document.getElementById('root'));
    });
  }
}


/*   render(
    <AppContainer>
      <Root store={store} history={history} />
    </AppContainer>,
    document.getElementById('root')
  );

  if (module.hot) {
    module.hot.accept('./components/Root', () => {
      const NewRoot = require('./components/Root').default;
      render(
        <AppContainer>
          <NewRoot store={store} history={history} />
        </AppContainer>,
        document.getElementById('root')
      );
    });
  } */



else {
  console.error("something went wrong, not showing content");
}




// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

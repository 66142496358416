import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import settings from './settings';
import { createHashHistory } from "history";

export const history = createHashHistory();

const reducers = {
  router: connectRouter(history),
  settings
};

export default combineReducers(reducers);
